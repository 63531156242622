<template>
  <div>
    <public-header />

    <div class="wrapper">
      <section class="banner-section">
        <div class="container">
          <v-row
            class="align-items-center"
          >
            <v-col
              cols="12"
              lg="6"
              md="6"
            >
              <div class="big-text">
                Streamline your communications with the <span>#1 SMS marketing platform.</span>
              </div>
              <div class="small-text">
                Let’s Connect helps businesses to transform their communication through SMS, MMS, Voice, Email, and Video Conferencing software infrastructure.
              </div>
              <div class="buttons">
                <a
                  href="#"
                  class="button-dark-outline mr-3"
                >
                  Who are we
                  <div class="icon-wrapper">
                    <img
                      src="../../assets/lc_img/icons/sign-in.svg"
                      alt=""
                    >
                  </div>
                </a>

                <a
                  href="#"
                  class="button-dark-solid"
                >
                  Get started
                  <div class="icon-wrapper">
                    <img
                      src="../../assets/lc_img/icons/sign-in-white.svg"
                      alt=""
                    >
                  </div>
                </a>
              </div>
            </v-col>

            <v-col
              cols="12"
              lg="6"
              md="6"
            >
              <div class="big-image-wrapper">
                <a
                  href="#"
                  class="service-item"
                >
                  <div class="icon-wrapper">
                    <img src="../../assets/lc_img/service1.png">
                  </div>
                  <span>Marketing & Sales</span>
                </a>
                <a
                  href="#"
                  class="service-item"
                >
                  <div class="icon-wrapper">
                    <img src="../../assets/lc_img/service2.png">
                  </div>
                  <span>HR & Employee Sales Service</span>
                </a>
                <a
                  href="#"
                  class="service-item"
                >
                  <div class="icon-wrapper">
                    <img src="../../assets/lc_img/service3.png">
                  </div>
                  <span>MMS Services</span>
                </a>
                <a
                  href="#"
                  class="service-item"
                >
                  <div class="icon-wrapper">
                    <img src="../../assets/lc_img/service4.png">
                  </div>
                  <span>Customer Service</span>
                </a>
                <a
                  href="#"
                  class="service-item"
                >
                  <div class="icon-wrapper">
                    <img src="../../assets/lc_img/service5.png">
                  </div>
                  <span>IVR Voice Call Services</span>
                </a>
                <a
                  href="#"
                  class="service-item"
                >
                  <div class="icon-wrapper">
                    <img src="../../assets/lc_img/service6.png">
                  </div>
                  <span>Customer Service</span>
                </a>

                <div class="big-image">
                  <img
                    src="../../assets/lc_img/banner-img.png"
                    alt=""
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </div>

        <div class="scrollDown d-none d-mb-block">
          <img
            src="../../assets/lc_img/icons/down-arrow-circle.svg"
            alt=""
          >
        </div>
      </section>

      <section class="services">
        <div class="container p-0">
          <v-row>
            <v-col
              cols="12"
              lg="2"
              md="2"
              class="py-0"
            >
              <div class="serviceTabs">
                <div class="serviceTab active">Marketing and sales services</div>
                <div class="serviceTab">Customer Service</div>
                <div class="serviceTab">HR & Employee Sales</div>
                <div class="serviceTab">IVR Voice call Services</div>
                <div class="serviceTab">MMS Services</div>
                <div class="serviceTab">Email Services</div>
              </div>
            </v-col>

            <v-col
              cols="12"
              lg="10"
              md="10"
            >
              <div class="serviceContent">
                <div class="sectionLeft">
                  <div class="section-title ">Connect more, convert more with text marketing</div>

                  <div class="details">
                    <div class="icon">
                      <v-icon
                        large
                      >
                        mdi-check-circle
                      </v-icon>
                    </div>

                    <div class="textRight">
                      <div class="bold">Promote sales & drive traffic</div>
                      <div class="text">Share text-based discounts and special offers to boost traffic and sales.</div>
                    </div>
                  </div>

                  <div class="details">
                    <div class="icon">
                      <v-icon
                        large
                      >
                        mdi-check-circle
                      </v-icon>
                    </div>

                    <div class="textRight">
                      <div class="bold">Grow your base</div>
                      <div class="text">Enable Keywords, QR codes, and Sign-Up Forms to make it easy for customers to join your text list.</div>
                    </div>
                  </div>

                  <div class="details">
                    <div class="icon">
                      <v-icon
                        large
                      >
                        mdi-check-circle
                      </v-icon>
                    </div>

                    <div class="textRight">
                      <div class="bold">Engage members</div>
                      <div class="text">Build mobile loyalty programs and gather feedback on products and services.</div>
                    </div>
                  </div>

                  <div class="details">
                    <div class="icon">
                      <v-icon
                        large
                      >
                        mdi-check-circle
                      </v-icon>
                    </div>

                    <div class="textRight">
                      <div class="bold">Convert leads</div>
                      <div class="text">Text one-on-one with prospects and re-engage with those that have stopped answering to phone calls and emails.</div>
                    </div>
                  </div>

                  <div class="buttons">
                    <a
                      href="#"
                      class="button-dark-solid"
                    >
                      Read more
                      <div class="icon-wrapper">
                        <img
                          src="../../assets/lc_img/icons/sign-in-white.svg"
                          alt=""
                        >
                      </div>
                    </a>
                  </div>
                </div>

                <div class="sectionRight">
                  <div class="image-wrapper">
                    <img
                      src="../../assets/lc_img/service1-img.png"
                      alt=""
                    >
                  </div>

                  <div class="quotation">
                    <div class="leftWrap">
                      <div class="image-wrapper mb-3">
                        <img
                          src="../../assets/lc_img/user-img.png"
                          alt=""
                        >
                      </div>
                      <div class="details">
                        <span class="name">Haley Lanigan</span>
                        <span class="about">- Recruiting Manager</span>
                      </div>
                    </div>

                    <div class="rightWrap">
                      <div class="quote">
                        <img
                          src="../../assets/lc_img/icons/quote.svg"
                          alt=""
                        >
                      </div>
                      <div class="content">
                        Even I was suprised at the high engagment rate text messaging delivered! Almost no opt-outs, lots of follow-up, and very positive impact on recruitment
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>

          </v-row>
        </div>
      </section>

      <section class="featuresSmall">
        <div class="container">
          <v-row
            class="align-items-center"
          >
            <v-col
              cols="12"
              lg="5"
              md="5"
            >
              <div class="section-title">Reach more customers faster & easier with our marketing services.</div>
              <div class="buttons">
                <a
                  href="#"
                  class="button-light-solid mr-3"
                >
                  Get started for FREE
                  <div class="icon-wrapper">
                    <img
                      src="../../assets/lc_img/icons/sign-in-black.svg"
                      alt=""
                    >
                  </div>
                </a>
              </div>
            </v-col>

            <v-col
              cols="12"
              lg="7"
              md="7"
            >
              <div class="featureItems">
                <div class="featureItem">
                  <div class="image-wrapper">
                    <img
                      src="../../assets/lc_img/icons/user-circle.svg"
                      alt=""
                    >
                  </div>

                  <div class="details">
                    <div class="bold">Send messages &</div>
                    <div class="text">conversational 2 way messages</div>
                  </div>
                </div>

                <div class="featureItem">
                  <div class="image-wrapper">
                    <img
                      src="../../assets/lc_img/icons/user-circle.svg"
                      alt=""
                    >
                  </div>

                  <div class="details">
                    <div class="bold">Exceptional support</div>
                    <div class="text">teams & online resources</div>
                  </div>
                </div>

                <div class="featureItem">
                  <div class="image-wrapper">
                    <img
                      src="../../assets/lc_img/icons/user-circle.svg"
                      alt=""
                    >
                  </div>

                  <div class="details">
                    <div class="bold">Flexible pricing</div>
                    <div class="text">for organizations of all sizes</div>
                  </div>
                </div>

                <div class="featureItem">
                  <div class="image-wrapper">
                    <img
                      src="../../assets/lc_img/icons/user-circle.svg"
                      alt=""
                    >
                  </div>

                  <div class="details">
                    <div class="bold">10-digit local numbers,</div>
                    <div class="text">toll free numbers & dedicated short codes</div>
                  </div>
                </div>

                <div class="featureItem">
                  <div class="image-wrapper">
                    <img
                      src="../../assets/lc_img/icons/user-circle.svg"
                      alt=""
                    >
                  </div>

                  <div class="details">
                    <div class="bold">Unlimited contacts</div>
                    <div class="text">groups & 2 way messages</div>
                  </div>
                </div>

                <div class="featureItem">
                  <div class="image-wrapper">
                    <img
                      src="../../assets/lc_img/icons/user-circle.svg"
                      alt=""
                    >
                  </div>

                  <div class="details">
                    <div class="bold">Free trial</div>
                    <div class="text">no credit card or contact required</div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </section>

      <section class="featuresBig">
        <div class="container">
          <div class="section-subtitle text-center mb-4">Powerful features</div>
          <div class="section-title text-center mb-5">These are some of our powerful features</div>

          <v-row>
            <v-col
              cols="12"
              lg="3"
              md="3"
            >
              <div class="featureItem">
                <div class="image-wrapper">
                  <img
                    src="../../assets/lc_img/icons/message.svg"
                    alt=""
                  >
                </div>

                <div class="title">MMS picture messaging</div>
                <div class="details">Make your text marketing pop by adding pictures or audio and video files. MMS messages deliver more engagement and supersized ROI, boosting engagement by as much as 250%.</div>

                <div class="buttons">
                  <a
                    href="#"
                    class="button-dark-outline border-0 mr-3"
                  >
                    Read more
                    <div class="icon-wrapper">
                      <img
                        src="../../assets/lc_img/icons/sign-in.svg"
                        alt=""
                      >
                    </div>
                  </a>
                </div>
              </div>
            </v-col>

            <v-col
              cols="12"
              lg="3"
              md="3"
            >
              <div class="featureItem">
                <div class="image-wrapper">
                  <img
                    src="../../assets/lc_img/icons/message.svg"
                    alt=""
                  >
                </div>

                <div class="title">The EZ texting mobile app</div>
                <div class="details">Manage your account, add contacts, and stay connected to your customers, wherever you are, by downloading the free EZ Texting iOS mobile app.</div>

                <div class="buttons">
                  <a
                    href="#"
                    class="button-dark-outline border-0 mr-3"
                  >
                    Read more
                    <div class="icon-wrapper">
                      <img
                        src="../../assets/lc_img/icons/sign-in.svg"
                        alt=""
                      >
                    </div>
                  </a>
                </div>
              </div>
            </v-col>

            <v-col
              cols="12"
              lg="3"
              md="3"
            >
              <div class="featureItem">
                <div class="image-wrapper">
                  <img
                    src="../../assets/lc_img/icons/message.svg"
                    alt=""
                  >
                </div>

                <div class="title">Two-way texting & text-to-landline</div>
                <div class="details">Nearly 70% of consumers want to text businesses. Use two-way texting to perfect the conversational customer experience, and upgrade your existing landline to send and receive text messages.</div>

                <div class="buttons">
                  <a
                    href="#"
                    class="button-dark-outline border-0 mr-3"
                  >
                    Read more
                    <div class="icon-wrapper">
                      <img
                        src="../../assets/lc_img/icons/sign-in.svg"
                        alt=""
                      >
                    </div>
                  </a>
                </div>
              </div>
            </v-col>

            <v-col
              cols="12"
              lg="3"
              md="3"
            >
              <div class="featureItem">
                <div class="image-wrapper">
                  <img
                    src="../../assets/lc_img/icons/message.svg"
                    alt=""
                  >
                </div>

                <div class="title">Dedicated short codes</div>
                <div class="details">A dedicated short code is a 5- or 6-digit number that is reserved for use by one business. Dedicated short codes offer guaranteed throughput, unlimited Keywords, and low messaging fees.</div>

                <div class="buttons">
                  <a
                    href="#"
                    class="button-dark-outline border-0 mr-3"
                  >
                    Read more
                    <div class="icon-wrapper">
                      <img
                        src="../../assets/lc_img/icons/sign-in.svg"
                        alt=""
                      >
                    </div>
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </section>

      <section class="infoBox">
        <div class="container">
          <v-row
            class="align-items-center"
          >
            <v-col
              cols="12"
              lg="6"
              md="6"
            >
              <div class="section-title text-light">Measure your success with analytics & reporting</div>
              <div class="text text-light my-5">EZ Texting’s built-in reporting dashboard provides invaluable insight into your text marketing strategy and messaging trends. Watch your contacts and messaging data closely to optimize your campaigns.</div>
              <div class="buttons">
                <a
                  href="#"
                  class="button-light-solid"
                >
                  Learn more
                  <div class="icon-wrapper">
                    <img
                      src="../../assets/lc_img/icons/sign-in-black.svg"
                      alt=""
                    >
                  </div>
                </a>
              </div>
            </v-col>

            <v-col
              cols="12"
              lg="6"
              md="6"
            >
              <img
                src="../../assets/lc_img/info-img.png"
                alt=""
              >
            </v-col>
          </v-row>
        </div>
      </section>
    </div>

    <public-footer />
  </div>
</template>

<script>
  // import carousel from 'vue-owl-carousel'

  export default {
    name: 'LandingIndex',

    components: {
      PublicHeader: () => import('../../views/dashboard/components/PublicHeader'),
      PublicFooter: () => import('../../views/dashboard/components/PublicFooter'),
      // carousel,
    },

    data: () => ({
      model: 0,
      colors: [
        'indigo',
        'warning',
        'pink darken-2',
        'red lighten-1',
        'deep-purple accent-4',
      ],
      slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
      ],
      items: [
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
        },
      ],
      isUserLoggedIn: false,
      tabs: 1, // Opens Tab 2 by default. 0-indexed.
      tabItems: ['Marketing & Sales', 'Customer Service', 'HR & Employee Ops'],
      sectionSales: [
        {
          title: 'Connect More, Convert More with Text Marketing',
          text: [
            {
              strong: 'Promote Sales & Drive Traffic:',
              subText: 'Share text-based discounts and special offers to boost traffic and sales.',
            },
            {
              strong: 'Grow Your Base:',
              subText: 'Enable Keywords, QR codes, and Sign-Up Forms to make it easy for customers to join your text list.',
            },
            {
              strong: 'Engage Members: ',
              subText: 'Build mobile loyalty programs and gather feedback on products and services.',
            },
            {
              strong: 'Convert Leads:',
              subText: 'Text one-on-one with prospects and re-engage with those that have stopped answering to phone calls and emails.',
            },
          ],
          buttonTxt: 'MORE ON MARKETING SALES',
          blockQuote: {
            quote: 'When you send a text, it doesn\'t take very long til your phone\'s ringing and you\'re getting hits on your website. People are going to respond to what you sent.',
            author: {
              name: 'Jann Parker ',
              pos: 'Billings Livestock Commission',
            },
            logo: '',
          },
        },
        {
          title: 'Conquer Customer Experience with Text Marketing',
          text: [
            {
              strong: 'Send Alerts & Notifications:',
              subText: 'Instantly alert customers to new products and services or updates about your business.',
            },
            {
              strong: 'Manage Appointments:',
              subText: 'Make missed appointments and lost revenue a thing of the past with automated reminders.',
            },
            {
              strong: 'Confirm Orders & Deliveries:',
              subText: 'Automated texts make order confirmations and delivery updates a breeze.',
            },

          ],
          buttonTxt: 'MORE ON CUSTOMER SERVICE',
          blockQuote: {
            quote: 'Even I was suprised at the high engagment rate text messaging delivered! Almost no opt-outs, lots of follow-up, and very positive impact on recruitment.',
            author: {
              name: 'Haley Lanigan',
              pos: 'Recruiting Manager',
            },
            logo: '',
          },
        },
        {
          title: 'Level Up Internal Communications with Text Marketing',
          text: [
            {
              strong: 'Notify & Alert Employees:',
              subText: 'Keep your team safe, informed, and engaged by sending updates and alerts directly to their mobile devices.',
            },
            {
              strong: 'Manage Appointments:',
              subText: 'Drive employee referrals and streamline the recruiting process with the power of text.',
            },
            {
              strong: 'Confirm Orders & Deliveries:',
              subText: 'Quickly inform staff about schedule updates and make it easy to reply via text to pick up an open shift.',
            },

          ],
          buttonTxt: 'MORE ON HR & EMPLOYEE OPS',
          blockQuote: {
            quote: 'Using EZ Texting lets me connect with the right person at the right time. The tracking tool is awesome, making it clear that people review text way faster than email.',
            author: {
              name: 'Patrick Lewis ',
              pos: 'Sales',
            },
            logo: '',
          },
        },

      ],
      sectionTxtMarket: [
        {
          title: 'Send bulk messages',
          text: '& conversational 2 way messages',
        },
        {
          title: 'Exceptional support',
          text: 'teams & online resources',
        },
        {
          title: 'Flexible pricing',
          text: 'for organizations of all sizes',
        },
        {
          title: '10-digit local numbers,',
          text: ' toll free numbers & dedicated short codes',
        },
        {
          title: 'Unlimited contacts,',
          text: 'groups & 2 way messages',
        },
        {
          title: 'Free trial ',
          text: 'no credit card or contact required',
        },
      ],
      sectionPwrfl: [
        {
          title: 'MMS Picture Messaging',
          txt: 'Make your text marketing pop by adding pictures or audio and video files. MMS messages deliver more engagement and supersized ROI, boosting engagement by as much as 250%.',
          link: '#',
        },
        {
          title: 'The EZ Texting Mobile App',
          txt: 'Manage your account, add contacts, and stay connected to your customers, wherever you are, by downloading the free EZ Texting iOS mobile app.',
          link: '#',
        },
        {
          title: 'Two-Way Texting & Text-to-Landline',
          txt: 'Nearly 70% of consumers want to text businesses. Use two-way texting to perfect the conversational customer experience, and upgrade your existing landline to send and receive text messages.',
          link: '#',
        },
        {
          title: 'Dedicated Short Codes',
          txt: 'A dedicated short code is a 5- or 6-digit number that is reserved for use by one business. Dedicated short codes offer guaranteed throughput, unlimited Keywords, and low messaging fees.',
          link: '#',
        },
      ],
      sectionHilite: [{
                        title: 'Retail & Ecommerce',
                        txt: 'Ring up more sales when you employ high-powered, results-driven text marketing strategies.',
                      },
                      {
                        title: 'Marketing Agencies',
                        txt: 'See stellar ROI for you and your clients by adding SMS marketing to your services portfolio.',
                      },
                      {
                        title: 'Property Management',
                        txt: 'Generate qualified leads, streamline leasing operations, and more by adding text marketing.',
                      },
      ],
      sectionMeasure: [{
        title: 'Measure Your Success with Analytics & Reporting',
        txt: 'EZ Texting’s built-in reporting dashboard provides invaluable insight into your text marketing strategy and messaging trends. Watch your contacts and messaging data closely to optimize your campaigns.',
      }, {
        title: 'How a Renowned Horse Auction Lassos Sales with Text Marketing',
        txt: 'See how EZ Texting customer Jann Parker, Horse Sale Manager at Billings Horse Auction in Billings Montana, gets extra horsepower using text marketing to power her promotions, nurture, and events communications.',
      },
      ],
      sectionTxtmartrsc: [
        {
          title: 'GUIDES',
          text: 'SMS Marketing — The Ultimate Guide to Engaging & Effective SMS Marketing in 2022',
        },
        {
          title: 'BLOG POSTS',
          text: 'SMS vs. MMS - What\'s the Difference?',
        },
        {
          title: 'GUIDES',
          text: 'Mass Text Messaging Guide & How To Send A Mass Text',
        },
      ],
      articles: [
        { txt: 'Automate Your Text Marketing with EZ Texting’s HubSpot Integration' },
        { txt: 'How to Use SMS Marketing for Marketing Agencies' },
      ],
      ebooks: [
        {
          txt: '4 Types of Mobile Coupons',
        },
        {
          txt: '12 Secrets of Text Marketing',
        },
      ],
      videos: [
        {
          txt: 'Sending Your First Text Message',
        },
        {
          txt: 'Shutterstock Photo Library & Image Editor',
        },
        {
          txt: 'How Text Communications Keep a Dispersed Workforce Safe',
        },
        {
          txt: 'Best Tips for Text Marketing in 2021',
        },
      ],
    }),
    computed: {},
    created () {
      this.checkIfUserLoggedIn()
    },
    methods: {
      checkIfUserLoggedIn () {
        const username = localStorage.getItem('username')
        if (username && username.length) {
          this.isUserLoggedIn = true
        }
      },
      redirectToPage (path) {
        window.location = window.location.origin + path
      },
    },
  }
</script>

<style lang="scss">
  @import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css";

  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

  @import '../../assets/bootstrap.min.css';
  @import '../../assets/now-ui-kit.css';
  @import '../../assets/custom-style.css';
  @import '../../assets/custom-style-media.css';

  body * {
    font-family: "Poppins";
    font-size: 14px;
  }
</style>
